<template>
  <div class="bottom-main">
    <div class="flex justify-content-center">
      <div class="bottom-main-content flex  justify-content-between">
        <div>
          <div class="bottom-title1">关于科创</div>
          <div class="bottom-title2 mb20" @click="activeItem(6)">科创宣传</div>
          <div class="bottom-title2 mb20" @click="activeItem(7)">发展里程</div>
          <div class="bottom-title2 mb20" @click="activeItem(8)">科创文化</div>
          <div class="bottom-title2 mb20" @click="activeItem(9)">企业荣誉</div>
        </div>
        <div>
          <div class="bottom-title1">核心业务</div>
          <div class="bottom-title2 mb20" @click="activeItem(0)">解决方案</div>
          <div class="bottom-title2 mb20" @click="activeItem(1)">数字化平台</div>
          <div class="bottom-title2 mb20" @click="activeItem(2)">智能硬件</div>
        </div>
        <div>
          <div class="bottom-title1">部分案例</div>
          <div class="bottom-title2 mb20" @click="activeItem(3)">家禽企业</div>
          <div class="bottom-title2 mb20" @click="activeItem(4)">家畜企业</div>
          <div class="bottom-title2 mb20" @click="activeItem(5)">其他案例</div>
        </div>
        <div>
          <div class="bottom-title1">关注我们</div>
          <div><img src="../assets/images/bottom/qrcode.png"></div>
        </div>
        <div>
          <div class="bottom-title1">联系我们</div>
          <div class="bottom-title2 mb20">电话：0532-8898-4708</div>
          <div class="bottom-title2 mb20"> 邮箱：kcxd@kcxd-tech.com</div>
          <div class="bottom-title2 mb20">地址：青岛市城阳区山河路702号恒大御澜国际25号楼</div>
        </div>
      </div>
    </div>
    <div class="bottom-line"></div>
    <div class=" flex justify-content-center align-items-center">
      <div class="bottom-footer flex justify-content-center align-items-center bottom-title2">
        Copyright © 2021 KeChuangXinDa All Rights Reserved. 科创信达 版权所有 <a class="font-primary pointer" href="http://beian.miit.gov.cn">鲁ICP备17030464号-3</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottom",
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    }
  },
  methods: {
    activeItem(val) {
      this.active = val
      switch (val) {
        case 0:
          this.$router.push({name: 'Core', query: {showPage: 0}})
          break
        case 1:
          this.$router.push({name: 'Core', query: {showPage: 1}})
          break
        case 2:
          this.$router.push({name: 'Core', query: {showPage: 2}})
          break
        case 3:
          this.$router.push({name: 'Case', query: {active: 1}})
          break
        case 4:
          this.$router.push({name: 'Case', query: {active: 2}})
          break
        case 5:
          this.$router.push({name: 'Case', query: {active: 3}})
          break
        case 6:
          this.$router.push({name: 'About', query: {id: 'view1'}})
          break
        case 7:
          this.$router.push({name: 'About', query: {id: 'view2'}})
          break
        case 8:
          this.$router.push({name: 'About', query: {id: 'view3'}})
          break
        case 9:
          this.$router.push({name: 'About', query: {id: 'view4'}})
          break
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.bottom-main {
  width: 100%;
  background: #333333;
}

.bottom-main-content {
  width: 1200px;
  min-width: 1000px;
  margin-top: 60px;
}

.bottom-footer {
  width: 1200px;
  min-width: 1000px;
  padding: 20px 0;
}

.bottom-title1 {
  font-size: 18px;
  font-weight: bold;
  color: #F5F5F5;
  margin-bottom: 40px;
}

.bottom-title2 {
  font-size: 14px;
  font-weight: normal;
  color: #DBDBDB;

  &:hover {
    cursor: pointer;
    color: #FFFFFF;
    text-decoration: underline;
  }
}

.bottom-line {
  width: 100%;
  border: 1px solid #999999;
  margin-top: 60px;
}
</style>
