<template>
  <div class="home-view4 flex align-items-center justify-content-center">
    <div class="home-view4-content flex flex-warp align-items-center justify-content-between">
      <div style="width: 50%">
        <kcTitle title="公司简介" en="COMPANY PROFILE"></kcTitle>
        <div style="width: 580px; margin-top: 60px">
          <div class="font-size-18 font-bold mb10">
            畜牧产业数字化转型的推动者
          </div>
          <div class="font-size-14 mt20" style="line-height: 24px;">
            青岛科创信达科技有限公司成立于2014年，依托新一代信息技术，以智能硬件为基础，通过软硬件相协同的形式，服务畜牧企业向数字化、标准化产业转型升级，并提供整体解决方案。始终追求为客户创造价值，提供产学研成果转化、政府项目申报、供应链金融等服务。
          </div>
          <div class="font-size-14 mt20" style="line-height: 24px;">
            经过多年的奋斗已成为国际领先的环控器研发厂商，建设了国内领先的智能硬件电磁实验室，目前链接智能终端已经超过了二十万台，自主研发的小科爱牧数字化平台已经累计服务上万家养殖场。
          </div>
        </div>
        <img src="../../../assets/images/more.png" @click="$router.push({name: 'About'})" height="46" width="162" class="pointer" style="margin-top: 80px"/>
      </div>
      <div style="width: 50%">

        <img src="../../../assets/images/Home/computer.gif" width="100%"/>
      </div>
      <div ref="countTo" class="home-view4-count flex align-items-center justify-content-around">
        <div class="flex flex-column justify-content-center align-items-center">
          <div class="font-size-48 font-c-g flex align-items-start" style="vertical-align: top">
            <countTo ref="count1" :startVal='1998' :endVal='2014' separator="" :duration='3000'></countTo>
            <span class="font-size-20 mt5 ml5">年</span>
          </div>
          <div class="font-size-20">公司成立时间</div>
        </div>
        <div class="flex flex-column justify-content-center align-items-center">
          <div class="font-size-48 font-c-g flex align-items-start" style="vertical-align: top">
            <countTo ref="count2" :startVal='9800' :endVal='10000' separator="" :duration='1000'></countTo>
            <span class="font-size-20 mt5 ml5">+</span>
          </div>
          <div class="font-size-20">服务牧场数量</div>
        </div>
        <div class="flex flex-column justify-content-center align-items-center">
          <div class="font-size-48 font-c-g flex align-items-start" style="vertical-align: top">
            <countTo ref="count3" :startVal='187654' :endVal='300000' separator="" :duration='1000'></countTo>
            <span class="font-size-20 mt5 ml5">+</span>
          </div>
          <div class="font-size-20">链接智能终端数量</div>
        </div>
        <div class="flex flex-column justify-content-center align-items-center">
          <div class="font-size-48 font-c-g flex align-items-start" style="vertical-align: top">
            <countTo ref="count4" :startVal='1998' :endVal='2025' separator="" :duration='3000'></countTo>
            <span class="font-size-20 mt5 ml5">年</span>
          </div>
          <div class="font-size-20">成为行业内头部企业</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
import {isElementInViewport} from "@/utils/index.js";
export default {
  name: "view1",
  components: {
    countTo
  },
  data() {
    return {
      host: process.env.VUE_APP_BASE_API.split(':')[0] + ':' + process.env.VUE_APP_BASE_API.split(':')[1],
      interval: undefined,
      isFixed: true,
      time: undefined
    }
  },
  mounted() {
    this.time = setInterval(() => {
      this.isFixed = isElementInViewport(this.$refs.countTo)
    }, 1000)
  },
  watch: {
    isFixed(val) {
      if (val) {
        this.$refs.count1.start()
        this.$refs.count2.start()
        this.$refs.count3.start()
        this.$refs.count4.start()
      }
    }
  },
  destroyed() {
    clearInterval(this.interval)
    clearInterval(this.time)
  },
  methods: {
    goPage() {
      this.$router.push({ path: '/About' })
    },
  }
}
</script>

<style lang="scss" scoped>
.home-view4 {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 60px;
  .home-view4-content {
    min-width: 1200px;
    width: 1440px;
    .home-view4-count {
      background: url("../../../assets/images/Home/count-bg.png") no-repeat 100% 100%;
      width: 100%;
      margin-top: 80px;
      padding: 32px 0;
    }
  }
}
</style>
