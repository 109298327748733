<template>
<div  class="hardware">
  <div class="hardware-content">
    <kcTitle  title="智能硬件" en="Intelligent Hardware"></kcTitle>
    <div style="margin-top: 60px" class="pointer">
      <img src="../../../assets/images/Home/img/hardware.png"  @click="$router.push({name: 'Core', query: {showPage: 2}})"  width="100%">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "view9"
}
</script>

<style lang="scss" scoped>
.hardware{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  .hardware-content{
    min-width: 1200px;
    width: 1440px;
  }
}
</style>
