<template>
  <div
      class="ScaleBox"
      ref="ScaleBox"
      :style="{
      width: width + 'px',
      height: height + 'px',
    }"
  >
    <slot></slot>
  </div>
</template>
<script>

export default {
  name: "SuperBox",
  props: {},
  data() {
    return {
      scale: "",
      width: 1920,
      height: 937,
    };
  },
  mounted() {
    this.setScale();
    window.addEventListener("resize", this.debounce(this.setScale, 800));
  },
  methods: {
    getScale() {
      let {width, height} = this;
      let wh = window.innerHeight / height;
      let ww = window.innerWidth / width;
      return ww < wh ? ww : wh;
    },
    setScale() {
      this.height = ((window.innerHeight - 60) / window.innerWidth * 1920)
      this.scale = this.getScale();
      if (this.$refs.ScaleBox) {
        this.$refs.ScaleBox.style.setProperty("--scale", this.scale);
      }
    },
    debounce(fn, delay) {
      let delays = delay || 500;
      let timer;
      return function () {
        let th = this;
        let args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          timer = null;
          fn.apply(th, args);
        }, delays);
      };
    },
  },
};
</script>

<style lang="scss">
#ScaleBox {
  --scale: 1;
}

.ScaleBox {
  position: absolute;
  transform: scale(var(--scale)) translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  transform-origin: 0 0;
  left: 50%;
  top: calc(50% + 30px);
  transition: 0s;
  z-index: 999;
  overflow: hidden;
}
</style>
