<template>
  <div class="addressForPhone">
    <div class="address-dv">
      <div>{{ $t('lang.CorporateHQ') }}</div>
      <div class="mt5">{{ $t('lang.address') }}</div>
      <div class="mt10">{{ $t('lang.ProductionBase') }}</div>
      <div class="mt5">
        {{ $t('lang.address2') }}
      </div>
      <div class="mt5">
        Copyright © 2021 KeChuangXinDa All Rights Reserved. 科创信达 版权所有 <a style=" color: #AAAAAA;" href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备17030464号-3</a>
      </div>
    </div>
    <div style="height:40px"></div>
  </div>
</template>

<script>
export default {
  name: "addressForPhone",

  methods: {},
};
</script>

<style lang="scss" scoped>
.address-dv {
  box-sizing: border-box;
  margin-top: 5px;
  background-color: #000;
  width: 100%;
  color: #aaaaaa;
  padding: 20px;
  font-size: 0.8rem;

}
</style>
