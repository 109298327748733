import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookies from 'js-cookie'
// import './permission'
// 国际化插件
import VueI18n from 'vue-i18n'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';
import './assets/kc.css'

Vue.config.productionTip = false
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

// 全局组件挂载
import kcTitle from "@/components/kc-title/kcTitle";
import kcTitle2 from "@/components/kc-title/kcTitle2";
Vue.component('kcTitle', kcTitle)
Vue.component('kcTitle2', kcTitle2)

// 国际化插件引用
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    'zh': require('@/assets/lang/zh.js'),
    'en': require('@/assets/lang/en.js')
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
