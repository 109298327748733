<template>
    <div class="bottomBarForPhone">
        <div
            class="bottom-item"
            v-for="(item, index) in tabList"
            :key="item.name"
            :class="index == active ? 'active' : ''"
            @click="checkPage(index)"
        >
            <img :src="index == active ? item.srcActive : item.src" alt=""/> <br/>
            {{ $i18n.locale == 'zh' ? item.name : item.name2 }}
        </div>
    </div>
</template>
<script>
export default {
    name: "bottomBarForPhone",
    data() {
        return {
            tabList: [
                {
                    name: "首页",
                    name2: "Home",
                    src: require("../../assets/images/phone/u120.png"),
                    srcActive: require("../../assets/images/phone/u120_selected.png"),
                },
                {
                    name: "业务",
                    name2: "Business",
                    src: require("../../assets/images/phone/u125.png"),
                    srcActive: require("../../assets/images/phone/u125_selected.png"),
                },
                {
                    name: "案例",
                    name2: 'Case',
                    src: require("../../assets/images/phone/u130.png"),
                    srcActive: require("../../assets/images/phone/u130_selected.png"),
                },
                {
                    name: "合作",
                    name2: "Partner",
                    src: require("../../assets/images/phone/u116.png"),
                    srcActive: require("../../assets/images/phone/u135_selected.png"),
                },
                {
                    name: "新闻",
                    name2: "News",
                    src: require("../../assets/images/phone/u140.png"),
                    srcActive: require("../../assets/images/phone/u140_selected.png"),
                },
                {
                    name: "关于",
                    name2: "About",
                    src: require("../../assets/images/phone/u145.png"),
                    srcActive: require("../../assets/images/phone/u145_selected.png"),
                },
                {
                    name: "加入",
                    name2: "Join",
                    src: require("../../assets/images/phone/u150.png"),
                    srcActive: require("../../assets/images/phone/u150_selected.png"),
                },
            ],
            active: 0,
        };
    },
    methods: {
        checkPage(index) {
            this.active = index;
            switch (index) {
                case 0:
                    this.$router.push({path: "/Home"});
                    break;
                case 1:
                    this.$router.push({path: "/ProjectForPhone"});
                    break;
                case 2:
                    this.$router.push({path: "/CaseForPhone"});
                    break;
                case 3:
                    this.$router.push({path: "/CooperationForPhone"});
                    break;
                case 4:
                    this.$router.push({path: "/NewsForPhone"});
                    break;
                case 5:
                    this.$router.push({path: "/AboutForPhone"});
                    break;
                case 6:
                    this.$router.push({path: "/JoinForPhone"});
                    break;
            }
        },
    },
    watch: {
        $route() {
            let path = this.$route.path;
            if (path.indexOf("Home") != -1) {
                this.active = 0;
            } else if (path.indexOf("ProjectForPhone") != -1) {
                this.active = 1;
            } else if (path.indexOf("CaseForPhone") != -1) {
                this.active = 2;
            } else if (path.indexOf("CooperationForPhone") != -1) {
                this.active = 3;
            } else if (path.indexOf("NewsForPhone") != -1) {
                this.active = 4;
            } else if (path.indexOf("AboutForPhone") != -1) {
                this.active = 5;
            } else if (path.indexOf("JoinForPhone") != -1) {
                this.active = 6;
            }
        },
    },
    mounted() {
        let path = this.$route.path;
        if (path.indexOf("Home") != -1) {
            this.active = 0;
        } else if (path.indexOf("ProjectForPhone") != -1) {
            this.active = 1;
        } else if (path.indexOf("CaseForPhone") != -1) {
            this.active = 2;
        } else if (path.indexOf("CooperationForPhone") != -1) {
            this.active = 3;
        } else if (path.indexOf("NewsForPhone") != -1) {
            this.active = 4;
        } else if (path.indexOf("AboutForPhone") != -1) {
            this.active = 5;
        } else if (path.indexOf("JoinForPhone") != -1) {
            this.active = 6;
        }
    }
};
</script>
<style lang="scss" scoped>
.bottomBarForPhone {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #000;
    box-sizing: border-box;
    padding-top: 5px;
    z-index: 9999;
}

.bottom-item {
    color: #fff;
    width: 14%;
    text-align: center;
    font-size: 0.9rem;

    img {
        width: 20px;
    }
}

.active {
    color: #95f204;
}
</style>
