<template>
  <div class="house-view6 flex align-items-center justify-content-center" v-if="showData.length > 0">
    <div class="home-view6-content">
      <kcTitle style="margin-top: 60px" title="新闻资讯" en="NEWS"></kcTitle>
      <div style="margin-top: 50px;gap: 80px; align-items: stretch" class="flex justify-content-between">
        <div class="home-view6-left pointer" @click="skip(showData[0].newsUrl)">
          <img :src="showData[0].imgUrl" style="width: 100%"/>
          <div style="margin-top: 50px; height: 160px" class="flex flex-column justify-content-between">
            <div>
              <div class="main-title">
                {{showData[0].title}}
              </div>
              <div style="margin-top: 12px" class="main-info">
                {{showData[0].info}}
              </div>
            </div>
            <div class="split-line">
              <div class="split-line-item"></div>
            </div>
          </div>
        </div>
        <div class="home-view6-right pointer">
          <div style="width: 80px;height: 8px;background: #18933B;"></div>
          <div class="home-view6-right-item flex flex-column justify-content-between" v-for="(item,index) in showData.slice(1,4)" :key="index">
            <div @click="skip(item.newsUrl)">
              <div class="main-title2">
                {{ item.title }}
              </div>
              <div style="margin-top: 12px" class="main-info">
                {{ item.info }}
              </div>
            </div>
            <div class="split-line">
              <div class="split-line-item"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getWebsiteNewsInfo} from "@/api";

export default {
  name: "view6",
  data() {
    return {
      showData: []
      //     [
      //   {
      //     "params": null,
      //     "id": 21,
      //     "title": "行业动态",
      //     "info": "科技创新 信达天下 | 科创信达2022年终盛典圆满落幕，启航2023",
      //     "imgUrl": require('../../../assets/images/News/21.png'),
      //     "newsUrl": "https://mp.weixin.qq.com/s/rUuhxeAImqSVRoke2ELydg",
      //     "releaseTime": "2022-04-08",
      //     "enable": 1,
      //     "createBy": "",
      //     "createTime": "2022-03-05T00:00:00",
      //     "type": 1,
      //     info2: '2022年，科创信达始终坚持以奋斗者为本，持续创新，持续为客户创造价值，在产业数字化的道路上取得了丰硕的成果。2023年1月16日，以“科技创新 信达天下”为主题的科创信达2022年终盛典暨年度表彰大会圆满落下帷幕。'
      //   },
      //   {
      //     "params": null,
      //     "id": 15,
      //     "title": "行业动态",
      //     "info": "4亿！科创信达完成A轮融资！",
      //     "imgUrl": "https://www.ecofuturefarm.com/resource/images/kcow/202203030101.png",
      //     "newsUrl": "https://mp.weixin.qq.com/s?__biz=MzUxMjk4ODEyOA==&mid=2247496618&idx=1&sn=f2933763ebb21dd3d1a517225df5fea4&chksm=f95eb525ce293c33eaa29e8910f1c9d1320b71ff111dfb0d7ebcf0cd7faa44ba19eccf1145cd&token=1195287621&lang=zh_CN#rd",
      //     "releaseTime": "2022-04-08",
      //     "enable": 1,
      //     "createBy": "",
      //     "createTime": "2022-03-05T00:00:00",
      //     "type": 1,
      //     info2: '近日，青岛科创信达科技有限公司完成A轮融资，估值4亿元！融资规模达数千万，本轮由青岛市城阳区阳光创新投资有限公司投资，隶属于青岛市城阳区国资委。'
      //   },
      //   // {
      //   //   "params": null,
      //   //   "id": 18,
      //   //   "title": "行业动态",
      //   //   "info": "合作共赢|《智慧畜牧青岛宣言》内容首发！",
      //   //   "imgUrl": "https://www.ecofuturefarm.com/resource/images/kcow/202203030104.png",
      //   //   "newsUrl": "https://mp.weixin.qq.com/s?__biz=MzUxMjk4ODEyOA==&mid=2247495374&idx=1&sn=31b89147b7f36f79c4da8389f6e616fd&chksm=f95ea841ce292157febb4cac4c088f75e9c52f4324b88e29f6e7664bca0c9f5c3d6199d73600&token=1006595884&lang=zh_CN#rd",
      //   //   "releaseTime": "2022-04-27",
      //   //   "enable": 0,
      //   //   "createBy": "",
      //   //   "createTime": "2022-03-04T00:00:00",
      //   //   "type": 1,
      //   //   info2: '2021第二届智慧养殖高峰论坛在青岛隆重召开，论坛期间《智慧畜牧青岛宣言》正式发布，畜牧行业专家齐聚一堂...'
      //   // },
      //   {
      //     "params": null,
      //     "id": 20,
      //     "title": "行业动态",
      //     "info": "叙情谊，促合作 | 青岛科技大学校长陈克正一行到访科创信达",
      //     "imgUrl": "https://www.ecofuturefarm.com/resource/images/kcow/202203030106.png",
      //     "newsUrl": "https://mp.weixin.qq.com/s?__biz=MzUxMjk4ODEyOA==&mid=2247496607&idx=1&sn=ed54901617d5ba0b740b3602c2fa1a7b&chksm=f95eb510ce293c06181fcd48db927449ca7e7833b95076df14120914d4b905ca99c55eda1619&token=1006595884&lang=zh_CN#rd",
      //     "releaseTime": "2022-05-09",
      //     "enable": 1,
      //     "createBy": "",
      //     "createTime": "2022-03-03T00:00:10",
      //     "type": 1,
      //     info2: '1月17日下午，青岛科技大学校长陈克正，党委常委、副校长吕万翔，校务委员、合作发展处处长聂广明等领导一行莅临科创信达走访调研。'
      //   },
      //   {
      //     "params": null,
      //     "id": 19,
      //     "title": "行业动态",
      //     "info": "省级大数据平台落地！科创信达与山东疫控达成战略合作",
      //     "imgUrl": "https://www.ecofuturefarm.com/resource/images/kcow/202203030105.png",
      //     "newsUrl": "https://mp.weixin.qq.com/s?__biz=MzUxMjk4ODEyOA==&mid=2247494897&idx=1&sn=a1e2d972258eb30595f0273889ea6e97&chksm=f95eaa7ece2923684745396c6f24f5d439f47e9dddfb658c403b18f2dec7aa5ae127c0c5513f&token=1006595884&lang=zh_CN#rd",
      //     "releaseTime": "2022-05-05",
      //     "enable": 0,
      //     "createBy": "",
      //     "createTime": "2022-03-03T00:00:09",
      //     "type": 1,
      //     info2: '8月31日，青岛科创信达科技有限公司（以下简称“科创信达”）与山东省动物疫病预防与控制中心（以下简称“山东疫控”）在山东济南...'
      //   }
      // ]
    }
  },
  mounted() {
    getWebsiteNewsInfo().then(res => {
      this.showData = res.data.data.filter(item => item.enable == 1)
    })
    // getWebsiteNewsInfo().then(res => {
    //   this.showData = res.data.data
    //   console.log(this.showData);
    // })
  },
  methods: {
    goPage() {
      this.$router.push({ path: '/News' })
    },
    skip(url){
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.home-view6-content {
  min-width: 1200px;
  width: 1440px;
  .home-view6-left {
    width: 60%;
    &:hover > div > div > .main-title{
      color: #18933B;
    }
    //&:hover > div > div > .main-info{
    //  color: #18933B;
    //}
    &:hover > div > div > .split-line-item{
      width: 100%;
    }
  }
  .home-view6-right {
    width: 40%;
    .home-view6-right-item {
      height: calc((100% - 8px) / 3);
      &:hover > div > .main-title2{
        color: #18933B;
      }
      //&:hover > div > .main-info{
      //  color: #18933B;
      //}
      &:hover > div > .split-line-item{
        width: 100%;
      }
    }
  }
  img {
    display: block;
  }
  .main-title {
    font-size: 32px;
    font-weight: 600;
    color: #000000;
    transition: all 0.3s;
  }
  .main-title2 {
    padding-top: 60px;
    font-size: 20px;
    font-weight: 600;
    transition: all 0.3s;
  }
  .main-info {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 23px;
    transition: all 0.3s;
  }
  .split-line {
    width: 100%;
    height: 2px;
    background: #D4D4D4;
    .split-line-item {
      width: 10%;
      height: 100%;
      background: #18933B;
      transition: all 0.3s;
    }
  }
}
</style>
