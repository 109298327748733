<template>
  <div >

    <!-- web端 start-->
    <div class="app-for-web" v-if="show == 0">
      <top-bar />
        <div>
          <superBox>
            <router-view />
          </superBox>
        </div>
<!--      <div class="layout-bottom">-->
<!--        <div class="layout-bottom-item" @click="goPath(0)">{{ $t('lang.Solution') }}</div>-->
<!--        <div>|</div>-->
<!--        <div class="layout-bottom-item" @click="goPath(1)">{{ $t('lang.IoTPlatform') }}</div>-->
<!--        <div>|</div>-->
<!--        <div class="layout-bottom-item" @click="goPath(2)">{{ $t('lang.IntelligentHardware') }}</div>-->
<!--      </div>-->
    </div>
    <!-- web端 end -->
    <!-- 手机端 start -->
    <div class="app-for-phone"  v-if="show == 1">
      <TopBarForPhone :closeFlag="closeFlag"></TopBarForPhone>
      <div @click="closeFlag+=1" @touchstart="closeFlag+=1" class="phone-view">
        <router-view />
      </div>

      <BottomBarForPhone></BottomBarForPhone>
    </div>
    <!-- 手机端 end -->
  </div>
</template>

<script>
import TopBar from "@/layout/components/TopBar";
import TopBarForPhone from "@/layout/components/topBarForPhone";
import BottomBarForPhone from "@/layout/components/bottomBarForPhone"
import superBox from "../components/superBox/superBox";

export default {
  name: "index",
  components: {
    TopBar,
    TopBarForPhone,
    BottomBarForPhone,
    superBox
  },
  data(){
    return{
      closeFlag:0,
      show:0
    }
  },
  created() {
    window.addEventListener("resize", () => {
      if (document.body.clientWidth > 640) {
        this.show = 0
      }else {
        this.show = 1
      }
    });
  },
  methods: {
    goPath(val) {
      switch (val) {
        case 0:
          this.$router.push({ path: "/Project" });
          break;
        case 1:
          this.$router.push({ path: "/IOT" });
          break;
        case 2:
          this.$router.push({ path: "/Hardware" });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-bottom {
  position: absolute;
  bottom: -1px;
  height: 30px;
  width: 100%;
  background: #00834c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC",
    sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #ffffff;
}
.layout-bottom div {
  padding: 0px 10px;
}
.layout-bottom-item {
  &:hover {
    cursor: pointer;
  }
}
@media screen and (max-width: 640px) {
  .app-for-web {
    display: none;
  }
  .app-for-phone {
    display: block;
  }
}
@media screen and (min-width: 640px) {
  .app-for-web {
    display: block;
    min-width: 1200px;
  }
  .app-for-phone {
    display: none;
  }
}
.phone-view{
  z-index: 1;
}
</style>
