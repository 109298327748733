export const lang = {
    language: 'lan',
    Home: '科创首页', // 科创首页
    CoreBusiness: '核心业务', // 核心业务
    Solution: '解决方案', // 解决方案
    IoTPlatform: 'IoT平台', // IoT平台
    IntelligentHardware:'智能硬件', // 智能硬件
    CaseAppreciation:'案例鉴赏', // 案例鉴赏
    AllPartner: '全部企业', // 全部企业
    Partner: '合作伙伴', // 合作伙伴
    PoultryEnterprise: '家禽企业', // 家禽企业
    PigEnterprise: '猪业企业', // 猪业企业
    ConvergedEnterprise: '融合企业', // 融合企业
    UniversityEnterpriseCooperation: '校企合作', // 校企合作
    News: '新闻资讯', // 新闻资讯
    AllNews: '全部资讯', // 全部资讯
    XiaokeHeadline: '小科头条', // 小科头条
    IndustryNews: '行业动态', // 行业动态
    AboutKCXD: '关于科创', // 关于科创
    WhoisKCXD: '科创信达是谁', // 科创信达是谁
    WhatIsTheCultureOfKCXD: '科创文化是什么', // 科创文化是什么
    WhatIsTheRoadToKCXD: '科创之路是什么', // 科创文之路是什么
    CoreMember: '核心成员', // 核心成员
    ConsultantTeam: '顾问团队', // 顾问团队
    ContactUs: '联系我们', // 联系我们
    JoinUs: '联系我们', // 加入我们
    CampusRecruitment: '校园招聘', // 校园招聘
    SocialRecruitment: '社会招聘', // 社会招聘
    BusinessCooperation: '业务合作', // 业务合作
    OurAdvantages: '我们的优势', // 我们的优势
    CurrentStudentsRecentGraduates: '在校生&应届毕业生',
    IndustryCrossIndustryPersonage: '业内人士&优秀的跨行人士',


    AllCases: '全部案例', // 全部案例
    PigIndustryCase: '猪业案例', // 猪业案例
    PoultryIndustryCase: '禽业案例', // 禽业案例

    CorporateHQ: '公司总部', // 公司总部
    ProductionBase: '生产基地', // 生产基地
    address: '山东省青岛市城阳区山河路702号恒大御澜国际25-102#', // 地址
    address2: '山东省青岛市城阳区山河路702号恒大御澜国际（北门）向东1000米科创信达园区', // 地址2

    LearnMore: '进一步了解>', // 进一步了解
    InquiryNow: '立即询价', // 立即询价
    NewsInfo: '了解行业动态，关注物联生活', // 了解行业动态，关注物联生活

    IoTInfo: {
        title: '小科爱牧O²S智慧养殖大平台', // 小科爱牧O2S大平台
        msg1: '青岛科创信达科技有限公司致力于打造Only One System大平台，将传统小科爱牧物联网平台与专为养殖深度定制开发的ERP系统、OA办公系统、ARS应收账款系统、EQ电子报价系统的进行全面打通、深度融合，解决集团化企业的信息孤岛问题，为集团管理降本，为集团养殖增效。',
        msg2: '通过物联网、大数据、云计算等技术的深度融合，打造创新、协调、绿色、开放、共享的互联网平台，解决用户痛点，实现低风险、快产出、低人工、高品质、高收益的数智化养殖，为客户引流，为品牌赋能。'
    },
    advantageInfo: {
        OurAdvantage: '我们的优势', // 我们的优势，
        msg1: '一览众山小', // 一览众山小
        msg2: '极致的体验', // 极致的体验
        msg3: '十分的安全', // 十分的安全
        msg4: '精细的采集', // 精细的采集
        msg5: '深度的挖掘', // 深度的挖掘
        msg6: '一触即达', // 一触即达
    },
    HardWareInfo: {
        title0: '养猪专用环控器', // 养猪专用环控器
        title1: '家禽专用环控器', // 家禽专用环控器
        title2: '守护卫士', // 守护卫士
        title3: '物联网', // 物联网
        title4: '辅助精灵', // 辅助精灵
        title5: '传感器', // 传感器
        title6: '畜禽通用', // 畜禽通用
    },

    WhatIsOurCulture: {
        title: '我们的文化是什么',   //我们的文化是什么
        title1: '目标',
        msg1: '一个团队，一个十年，一个千亿',
        title2: '愿景',
        msg2: '更健康的生活，更幸福的家庭',
        title3: '口号',
        msg3: '科技创新拓市场，信达天下赢未来',
        title4: '理念',
        msg4: '人才造就成功',
        title5: '动能',
        msg5: '科技驱动发展'
    },
    WhatIsTheRoadToKechuangXinda: '科创之路是什么',
    WhatAreOurStrengths: '我们的优势是什么',
    FiveAdvantages: '高责任感、高使命感、高积极性、高主动性、高创造性', // High sense of responsibility, high sense of mission, high enthusiasm, high initiative, high creativity

    TheGrowthOfXiaokeAimu: '小科爱牧的成长',   //小科爱牧的成长


}
export const transfer = {
    t: (ctx) => {
        return ctx.values.t
    }
}
