<template>
    <div class="homeForPhone">
        <!-- 轮播 -->
        <el-carousel class="mt5 carousel-dv">
            <el-carousel-item>
                <img class="img" src="../assets/images/u2.png"/>
            </el-carousel-item>
            <el-carousel-item>
                <img class="img" src="../assets/images/u3.png"/>
            </el-carousel-item>
            <el-carousel-item>
                <img class="img" src="../assets/images/u4.png"/>
            </el-carousel-item>
        </el-carousel>

        <!-- 大平台 -->
        <div class="flex align-items-center justify-content-center mt5 relative">
            <div class="home-view1-title3" @click="goPage1">{{ $t('lang.LearnMore') }}</div>
            <img
                class="img2"
                v-if="count % 2 == 0"
                src="@/assets/images/phone/u7_div.png"
            />
            <img
                class="img2"
                v-if="count % 2 == 1"
                src="@/assets/images/phone/u9_div.png"
            />
        </div>
        <!-- Future x -->
        <div class="flex align-items-center justify-content-center mt5 relative">
            <div class="home-view1-title3" @click="goPage2">{{ $t('lang.LearnMore') }}</div>
            <img class="img2" src="@/assets/images/phone/u12_div2.png"/>
        </div>
        <!-- 科创视频 -->
        <div class="flex align-items-center justify-content-center mt5 video-dv">
            <div class="home-view1-title3 home-view1-title3-fixed" @click="goPage3">
                {{ $t('lang.LearnMore') }}
            </div>
            <div style="width: 700px">
                <div class="home-view4-title1">青岛科创信达科技有限公司</div>
                <div class="home-view4-title2">Qingdao KCXD Technology Co., Ltd</div>

                <div class="flex align-items-center justify-content-start">
                    <kc-video
                        :url="`https://www.ecofuturefarm.com/resource/videos/科创信达宣传片低清.mp4`"
                        type="mp4"
                        :is-live="false"
                        class="kc-video-dv"
                    />
                </div>
                <ul class="home-view4-title3" v-if="$i18n.locale == 'zh'">
                    <li>
            <span style="color: #95f204">打造养殖圈的小米生态</span
            >，始终坚持做"<span style="color: #95f204">感动人心，价格厚道</span
                    >"的好产品，给农业插上科技的翅膀，这是科创人的使命。
                    </li>
                    <li>
                      青岛科创信达科技有限公司是一家以<span style="color: #95f204">智能硬件和IoT平台</span
                    >为核心，提供<span style="color: #95f204"
                    >智慧养殖数智化解决方案</span
                    >的国家级高新技术企业，经过多年的奋斗已经成为国际领先的环控器研发厂商，建设了国内领先的智能硬件电磁实验室，自主研发的<span
                        style="color: #95f204"
                    >小科爱牧物联网平台</span
                    >已经累计服务上万家养殖场，目前链接智能终端已经超过了二十万台。
                    </li>
                </ul>
                <ul class="home-view4-title3" v-else>
                    <li>
                        Build the Xiaomi ecosystem of breeding industry. We always insist on making good products with
                        "touching people's hearts and competitive prices", the mission of KeChuangers are to put the
                        wings of science and technology on agriculture.
                    </li>
                    <li>
                        KeChuangXinDa is a national high-tech enterprise that regards Intelligent hardware and loT
                        platform as the core business and provides DIoT solution of intelligent breeding.
                        After seven years struggling, our company has become an international advanced researcher and
                        manufacturer on climate controllers which has built national leading intelligent hardware
                        electromagnetic laboratory. The self-developed XiaoKe AI Farm IoT platform has served over ten
                        thousands of farms. At present, more than 200,000 intelligent terminals have been connected.
                    </li>
                </ul>
            </div>
        </div>
        <!-- 新闻资讯 -->
        <div class="house-view6 mt5">
            <div class="home-view6-title1">{{ $t('lang.News') }}</div>
            <div class="home-view6-title2 mt10">
        <div class="pr10 pl10 pt20 home-view6-title2" style="text-align: center"
        >{{ $t('lang.NewsInfo') }}</div
        >
            </div>
            <div class="home-view6-title3 mt10" @click="goPage4">{{ $t('lang.LearnMore') }}</div>
            <div class="home-view6-img">
                <div
                    class="home-view6-img-item mt5"
                    v-for="(item, index) in showData"
                    :key="index"
                >
                    <img :src="item.imgUrl" width="100%"/>
                    <div class="news-tips-dv">
                        <div class="news-tip1">{{ item.title }}</div>
                        <div class="news-tip2">{{ item.info }}</div>
                        <div class="news-tip3">{{ item.releaseTime }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 合作伙伴 -->
        <div class="home-view5 mt5">
            <div class="home-view5-title1">{{ $t('lang.Partner') }}</div>
            <div class="home-view5-title2 mt10" v-if="$i18n.locale == 'zh'" >
                <span class="pr20 pt20 home-view5-title2">{{ $t('lang.PoultryEnterprise') }}</span>
                <span class="pr20 pt20 home-view5-title2">{{ $t('lang.PigEnterprise') }}</span>
                <span class="pt20 home-view5-title2">{{ $t('lang.ConvergedEnterprise') }}</span>
                <span class="ml10 pt20 home-view5-title2">{{ $t('lang.UniversityEnterpriseCooperation') }}</span>
            </div>
            <div class="home-view5-title2 flex flex-column" v-else>
                <div class="flex justify-content-center justify-content-around">
                    <span class="pr20 pt10 home-view5-title2">{{ $t('lang.PoultryEnterprise') }}</span>
                    <span class="pr20 pt10 home-view5-title2">{{ $t('lang.PigEnterprise') }}</span>
                </div>
                <div class="flex justify-content-center justify-content-around">
                    <span class="pt10 home-view5-title2">{{ $t('lang.ConvergedEnterprise') }}</span>
                    <span class="ml10 pt10 home-view5-title2">{{ $t('lang.UniversityEnterpriseCooperation') }}</span>
                </div>
            </div>
            <div class="home-view5-title3 mt10" @click="goPage5">{{ $t('lang.LearnMore') }}</div>
            <div style="width: 96%;" class="mt20 mb20">
                <div id="ms">
                    <table>
                        <tr>
                            <td id="Pic11">
                                <table class="table-dv">
                                    <tr>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u73.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u74.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u75.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u76.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u77.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u78.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u79.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u80.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u81.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u82.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u83.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u84.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u85.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u86.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u88.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u89.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u90.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u91.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u92.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u93.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u94.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u95.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u96.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u97.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u98.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u99.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u100.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src="@/assets/images/logo/u101.png"
                                                height="80"
                                                class="mr5 ml5"
                                            />
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td id="Pic22"></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <AddressForPhone></AddressForPhone>
    </div>
</template>
<script>
import kcVideo from "./kcVideo";
import {getWebsiteNewsInfo} from "@/api";
import AddressForPhone from "./addressForPhone";

export default {
    name: "homeForPhone",
    components: {
        kcVideo,
        AddressForPhone,
    },
    data() {
        return {count: 0, interval: undefined, showData: []};
    },
    mounted() {
        this.interval = window.setInterval(() => {
            this.count++;
        }, 2000);
        getWebsiteNewsInfo().then((res) => {
            this.showData = res.data.data.filter((val) => val.enable == 1);
        });
    },
    methods: {
        goPage1() {
            this.$router.push({path: "/ProjectForPhone"});
        },
        goPage2() {
            this.$router.push({path: "/ProjectForPhone"});
        },
        goPage3() {
            this.$router.push({path: "/AboutForPhone"});
        },
        goPage4() {
            this.$router.push({path: "/NewsForPhone"});
        },
        goPage5() {
            this.$router.push({path: "/CooperationForPhone"});
        },
    },
    destroyed() {
        clearInterval(this.interval);
    },
};
</script>
<style scoped>
/deep/ .el-carousel__arrow--left {
    display: none !important;
}

/deep/ .el-carousel__arrow--right {
    display: none !important;
}
</style>
<style lang="scss" scoped>
.carousel-dv {
    height: 150px;
    overflow: hidden;
}

.img {
    width: 100%;
    height: 150px;
}

> > > .el-carousel__arrow {
    display: none !important;
}

.img2 {
    width: 100%;
}

.home-view1-title3 {
    position: absolute;
    top: 25%;
    color: #2997ff;
    z-index: 99;
    font-size: 0.8rem;
}

.home-view1-title3-fixed {
    top: 18%;
}

.relative {
    position: relative;
}

.home-view4-title1 {
    margin-top: 40px;
    font-family: "ArialMT", "Arial", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 18px;
    letter-spacing: normal;
    color: #fff;
    line-height: normal;
    text-transform: none;
}

.home-view4-title2 {
    font-family: "ArialMT", "Arial", sans-serif;
    font-style: normal;
    font-size: 16px;
    letter-spacing: normal;
    color: #fff;
    line-height: normal;
    text-transform: none;
}

.home-view4-title3 {
    margin-top: 50px;
    font-family: "ArialMT", "Arial", sans-serif;
    font-style: normal;
    font-size: 13px;
    letter-spacing: normal;
    color: #fff;
    line-height: normal;
    text-transform: none;
    padding-right: 20px;
    text-align: start;
}

.home-view4-title4 {
    color: #2997ff;
    cursor: pointer;
}

.video-dv {
    background-color: #000;
    text-align: center;
    position: relative;
}

.kc-video-dv {
    width: 90%;
    margin: 50px auto 0 auto;
}

.house-view6 {
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.home-view6-title1 {
    padding-top: 30px;
    font-family: "PingFangTC-Semibold", "PingFang TC Semibold", "PingFang TC",
    sans-serif;
    font-weight: 650;
    font-style: normal;
    font-size: 26px;
    color: #ffffff;
}

.home-view6-title2 {
    font-family: "PingFangTC-Regular", "PingFang TC", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: #ffffff;
}

.home-view6-title3 {
    font-family: "PingFangTC-Regular", "PingFang TC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #2997ff;
    cursor: pointer;
}

.home-view6-img {
    width: 90%;
    margin: auto;
    margin-top: 20px;
}

.home-view6-img-item {
    position: relative;
}

.news-tips-dv {
    position: absolute;
    bottom: 0;
    margin-top: -50px;
    background: rgba($color: #000000, $alpha: 0.5);
    box-sizing: border-box;
    padding: 10px 20px;
    width: 100%;
}

.news-tip1 {
    font-size: 0.9rem;
}

.news-tip2 {
    font-size: 0.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-tip3 {
    font-size: 0.8rem;
}

.home-view5 {
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.home-view5-title1 {
    padding-top: 30px;
    font-family: "PingFangTC-Semibold", "PingFang TC Semibold", "PingFang TC",
    sans-serif;
    font-weight: 650;
    font-style: normal;
    font-size: 26px;
    color: #ffffff;
}

.home-view5-title2 {
    font-family: "PingFangTC-Regular", "PingFang TC", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: #ffffff;
}

.home-view5-title3 {
    font-family: "PingFangTC-Regular", "PingFang TC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #2997ff;
}

#ms {
    width: 100%;
    overflow-x: scroll;
}

@keyframes move {
    0% {
        margin-left: 0px;
    }
    100% {
        margin-left: -3500px;
    }
}

.table-dv {
    animation: move 30s infinite linear;

}
</style>
