<template>
  <div class="kc-title font-bold flex flex-column align-items-center justify-content-center">
    <div class="flex align-items-end font-size-40">
      <span>{{$t('transfer.t', {t: title})}}</span>
    </div>
    <div class="flex align-items-center font-size-18">
      <span>{{$t('transfer.t', {t: en.toUpperCase()})}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "kcTitle",
  props: {
    title: {
      type: String,
      default: ""
    },
    en: {
      type: String,
      default: ""
    },
  }

}
</script>

<style lang="scss" scoped>
.kc-title {
  color: #18933B;
  font-family: Inter V-Semi Bold, Inter V;
}
</style>
