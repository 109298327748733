<template>
  <div class="home-view5">
    <div class="home-view5-content">
      <kcTitle style="margin-top: 60px" title="合作伙伴" en="PARTNERS"></kcTitle>
      <div class="flex align-items-center justify-content-center">
        <div style="width: 90%" class="mt20 mb20">
          <div id="sm">  <!--滚动div-->
            <table>    <!--外表格1x2,且第2单元格是空的-->
              <tr>
                <td id="Pic1">
                  <table>  <!--内表格1x9,存放9张图片-->
                    <tr>
                      <td><img src="../../../assets/images/logo/u72.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u73.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u74.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u75.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u76.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u77.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u78.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u79.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u80.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u81.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u82.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u83.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u84.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u85.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u86.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u87.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u88.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u89.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u90.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u91.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u92.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u93.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u94.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u95.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u96.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u97.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u98.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u99.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u100.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u101.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u102.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u103.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u104.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u105.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u106.jpg" height="150" class="mr30 ml30"/></td>
                      <td><img src="../../../assets/images/logo/u107.jpg" height="150" class="mr30 ml30"/></td>
                    </tr>
                  </table>
                </td>
                <td id="Pic2"></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "view1",
  data() {
    return {}
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    let Pic2 = document.getElementById('Pic2')
    // eslint-disable-next-line no-unused-vars
    let Pic1 = document.getElementById('Pic1')
    Pic2.innerHTML = Pic1.innerHTML;
    // eslint-disable-next-line no-unused-vars
    let sm = document.getElementById('sm')
    // eslint-disable-next-line no-unused-vars
    let MyMar = setInterval(() => {
      sm.scrollLeft++;//改变层的水平坐标，实现向左移动
      if (sm.scrollLeft >= Pic1.scrollWidth)//需要复位
        sm.scrollLeft = 0;//层的位置复位，浏览器窗口的宽度有限的
    }, 20);
  },
  methods: {
    goPage() {
      this.$router.push({path: '/Cooperation'})
    }
  }
}
</script>

<style lang="scss" scoped>
.home-view5-content {
  min-width: 1200px;
  width: 1440px;
}

.home-view5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.home-view5-title1 {
  padding-top: 30px;
  font-family: 'PingFangTC-Semibold', 'PingFang TC Semibold', 'PingFang TC', sans-serif;
  font-weight: 650;
  font-style: normal;
  font-size: 26px;
  color: #FFFFFF;
}

.home-view5-title2 {
  font-family: 'PingFangTC-Regular', 'PingFang TC', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: #FFFFFF;
}

.home-view5-title3 {
  font-family: 'PingFangTC-Regular', 'PingFang TC', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #2997FF;
}

#sm {
  width: 100%;
  overflow: hidden;
}
</style>
