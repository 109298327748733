<template>
  <div class="case">
    <div class="case-content">
      <kcTitle title="案例鉴赏" en="Case Appreciation"></kcTitle>
      <div class="flex justify-content-between" style="width: 100%;margin-top: 60px;margin-bottom: 60px">
        <div style="width: calc(33% - 20px);" class="item-cont pointer" @click="$router.push({path: '/Case'})">
          <img src="../../../assets/images/Home/img/img3.png" width="100%">
          <div class="title-bg font-bold">海大集团海大智慧养殖大数据平台 </div>
        </div>
        <div style="width: calc(25% - 20px);" class="flex flex-column align-items-center justify-content-between">
          <div class="item-cont pointer"  @click="$router.push({path: '/Case'})">
            <img src="../../../assets/images/Home/img/img2.png" width="100%">
            <div class="title-bg font-bold">东方希望集团</div>
          </div>
          <div  class="item-cont mt20 pointer"  @click="$router.push({path: '/Case'})">
            <img src="../../../assets/images/Home/img/img4.png" width="100%">
            <div class="title-bg font-bold">安徽荣达项目</div>
          </div>
        </div>
        <div style="width: calc(42% - 20px);" class="flex flex-column align-items-center justify-content-between"  @click="$router.push({path: '/Case'})">
          <div class="item-cont pointer"  @click="$router.push({path: '/Case'})">
            <img src="../../../assets/images/Home/img/img1.png" width="100%">
            <div class="title-bg font-bold">亚太中慧集团</div>
          </div>
          <div class="item-cont mt20 pointer"  @click="$router.push({path: '/Case'})">
            <img src="../../../assets/images/Home/img/img5.png" width="100%">
            <div class="title-bg font-bold">新希望集团</div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "view10"
}
</script>

<style lang="scss" scoped>
.case {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  padding-top: 60px;
  background: #F5F5F5;

  .case-content {
    min-width: 1200px;
    width: 1440px;

    .case-cont-img {
      width: calc((100% / 3) - 20px);
    }
  }
}
.item-cont{
  position: relative;
  transition: all 0.3s;
  img {
    display: block;
  }
  &:hover > .title-bg {
    display: block;
    opacity: 1;
  }
  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16);
  }
}
.title-bg{
  position: absolute;
  text-align: center;
  height: 50px;
  background: rgba(0, 0, 0, 0.4);
  bottom: 0px;
  width: 100%;
  line-height: 50px;
  font-size: 16px;
  color: #FFFFFF;
  transition: all 0.3s;
  opacity: 0;
}
</style>
