<template>
  <div class="top-bar">
    <div class="flex align-items-center justify-content-center">
      <div style="width: calc((100% - 700px) / 2);">
        <div class="flex align-items-center justify-content-center pointer"
             style="height: 60px; width: 100%;background: #FFFFFF" @click="activeItem(0)">
          <img src="../../assets/images/u16.png" height="100%"/>
        </div>
      </div>
      <div style="width: 700px;">
        <div class=" top-bar-menu-item flex align-items-center justify-content-center"
             style="height: 60px; width: 100%;gap: 20px">
          <div class="top-bar-menu-item-title pointer" @click="activeItem(0)"
               :class="{'top-bar-menu-item-title-active': active == 0}">{{ $t('transfer.t', {t: '科创首页'}) }}
          </div>
          <div class="top-bar-menu-item-title pointer" @click="activeItem(5)"
               :class="{'top-bar-menu-item-title-active': active == 5}">{{ $t('transfer.t', {t: '关于科创'}) }}
          </div>
          <el-dropdown style="height: 100%;">
            <div class="top-bar-menu-item-title pointer" @click="activeItem(1)"
                 :class="{'top-bar-menu-item-title-active': active == 1}">
              <span class="test" style="font-weight: 900">{{ $t('transfer.t', {t: '核心业务'}) }}</span>
              <el-dropdown-menu slot="dropdown">
                <div v-for="(item,index) in solutionList" :key="index" @click="changeClick(index)">
                  <el-dropdown-item class="flex flex-warp justify-content-center pt10 pb10 menu-item-sub">{{ item }}</el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </div>
          </el-dropdown>
          <el-dropdown style="height: 100%;">
            <div class="top-bar-menu-item-title pointer" @click="activeItem(2)"
                 :class="{'top-bar-menu-item-title-active': active == 2}">
              <span class="test" style="font-weight: 900">{{ $t('transfer.t', {t: '案例鉴赏'}) }}</span>
              <el-dropdown-menu slot="dropdown">
                <div v-for="(item,index) in caseList" :key="index" @click="changeClick(index+1,'case')">
                  <el-dropdown-item class="flex flex-warp justify-content-center pt10 pb10 menu-item-sub">{{ item }}</el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </div>
          </el-dropdown>
          <div class="top-bar-menu-item-title pointer" @click="activeItem(4)"
               :class="{'top-bar-menu-item-title-active': active == 4}">{{ $t('transfer.t', {t: '新闻资讯'}) }}
          </div>
          <div class="top-bar-menu-item-title pointer" @click="activeItem(6)"
               :class="{'top-bar-menu-item-title-active': active == 6}">{{ $t('transfer.t', {t: '联系我们'}) }}
          </div>
        </div>
      </div>
      <div style="width: calc((100% - 700px) / 2);" class="flex justify-content-center">
        <el-dropdown style="height: 100%;">
          <div class="top-bar-menu-item-title pointer" @click="activeItem(1)"
               :class="{'top-bar-menu-item-title-active': active == 1}">
            <span class="test" style="font-weight: 900">{{ $t('transfer.t', {t: '中｜EN'}) }}</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item ><div  @click="$i18n.locale = 'zh'"><el-dropdown-item >中文</el-dropdown-item></div></el-dropdown-item>
              <el-dropdown-item ><div  @click="goEnPage()"><el-dropdown-item >English</el-dropdown-item></div></el-dropdown-item>
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  data() {
    return {
      activeIndex2: '1',
      active: 0,
      solutionList: ['解决方案', '数字化平台', '智能硬件',],
      caseList: ['家禽案例', '家畜案例', '其他案例',],
    }
  },
  mounted() {
    let path = this.$route.path
    if (path.indexOf('Home') != -1) {
      this.active = 0
    } else if (path.indexOf('Core') != -1 || path.indexOf('Project') != -1 || path.indexOf('IOT') != -1 || path.indexOf('Hardware') != -1) {
      this.active = 1
    } else if (path.indexOf('Case') != -1) {
      this.active = 2
    } else if (path.indexOf('Cooperation') != -1) {
      this.active = 3
    } else if (path.indexOf('News') != -1) {
      this.active = 4
    } else if (path.indexOf('About') != -1) {
      this.active = 5
    } else if (path.indexOf('Join') != -1 || path.indexOf('Campus') != -1 || path.indexOf('Sociology') != -1) {
      this.active = 6
    }
  },
  watch: {
    $route() {
      let path = this.$route.path
      if (path.indexOf('Home') != -1) {
        this.active = 0
      } else if (path.indexOf('Core') != -1 || path.indexOf('Project') != -1 || path.indexOf('IOT') != -1 || path.indexOf('Hardware') != -1) {
        this.active = 1
      } else if (path.indexOf('Case') != -1) {
        this.active = 2
      } else if (path.indexOf('Cooperation') != -1) {
        this.active = 3
      } else if (path.indexOf('News') != -1) {
        this.active = 4
      } else if (path.indexOf('About') != -1) {
        this.active = 5
      } else if (path.indexOf('Join') != -1 || path.indexOf('Campus') != -1 || path.indexOf('Sociology') != -1) {
        this.active = 6
      }
    },
  },
  methods: {
    seti18n() {
      if (this.$i18n.locale == 'zh') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'zh'
      }
    },
    changeClick(val,type) {
      if (type==='case'){
        this.$router.push({path: '/Case',query:{active:val}})
      }else{
        this.$router.push({path: '/Core',query:{showPage:val}})
      }
    },
    activeItem(val) {
      this.active = val
      switch (val) {
        case 0:
          this.$router.push({path: '/Home'})
          break
        case 1:
          this.$router.push({path: '/Core'})
          break
        case 2:
          this.$router.push({path: '/Case'})
          break
        case 3:
          this.$router.push({path: '/Cooperation'})
          break
        case 4:
          this.$router.push({path: '/News'})
          break
        case 5:
          this.$router.push({path: '/About'})
          break
        case 6:
          this.$router.push({path: '/Join'})
          break
      }
    },
    activeSub(val, sub) {
      this.active = val
      if (val == 1) {
        switch (sub) {
          case 0:
            this.$router.push({path: '/Project'})
            break
          case 1:
            this.$router.push({path: '/IOT'})
            break
          case 2:
            this.$router.push({path: '/Hardware'})
            break
        }
      }
      if (val == 2) {
        switch (sub) {
          case 0:
            this.$router.push({path: '/Campus'})
            break
          case 1:
            this.$router.push({path: '/Sociology'})
            break
        }
      }

    },
    goEnPage() {
      window.open('https://www.ccsdatech.com', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.top-bar {
  height: 60px;
  width: 100%;
  background-color: white;
}

.top-bar-menu-item {
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  font-weight: 900;
  font-size: 14px;

  .top-bar-menu-item-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0px 20px;

    &:hover {
      background-color: #00834c;
      color: white;
      transition: background-color 0.3s;
      transition: color 0.3s;
    }
  }

  .top-bar-menu-item-title-active {
    background-color: #00834c;
    color: white;
    transition: background-color 0.3s;
    transition: color 0.3s;
  }
}

.top-bar-menu-item-sub {
  padding: 10px 70px;
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  color: #2c4198;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0);

  &:hover {
    cursor: pointer;
    color: #2c4198;
    border-bottom: 2px solid #2c4198;
  }
}

.el-dropdown {
color: black !important;
}
</style>
<style>
</style>
