<template>
  <div>
    <div class="topBarForPhone">
      <div class="logo">
        <img src="../../assets/images/u16.png" height="26" width="116" />
      </div>
      <div class="flex contact">

        <img
          :src="
            phone
              ? require('../../assets/images/phone/u160_selected.png')
              : require('../../assets/images/phone/u160.png')
          "
          @click="phoneSwitch"
        />
        &emsp;
        <img
          :src="
            video
              ? require('../../assets/images/phone/u163_selected.png')
              : require('../../assets/images/phone/u163.png')
          "
          @click="videoSwitch"
        />
          <el-tooltip class="item ml10" effect="dark" placement="bottom" >
              <div slot="content">
                  <div class="top-bar-menu-item-sub" @click="$i18n.locale = 'zh'">
                      中文
                  </div>
                  <div class="top-bar-menu-item-sub" @click="$i18n.locale = 'en'">
                      English
                  </div>
              </div>
              <div class=" pointer flex align-items-center justify-content-center"
                   style="color: white;font-weight: 900;font-size: 14px;">lan
              </div>
          </el-tooltip>
      </div>
    </div>
    <!-- 二维码 start -->
    <div class="twoCode-dv" v-if="phone">
      <div
        class="twoCode-dc-item"
        v-for="item in contactData"
        :key="item.title"
      >
        <div><img :src="item.url" /></div>
        <div style="color: #fff; margin-left: 10px">
          <div class="txt1">{{ item.title }}</div>
          <div class="txt2">{{ item.phone }}</div>
          <div class="txt3">{{ item.address }}</div>
        </div>
      </div>
    </div>
    <!-- 二维码 end -->
    <!-- 视频号 start -->
    <div class="video-dv" v-if="video">
      <div
        v-for="(item, index) in medioData"
        :key="item.name"
        class="video-item-dv"
        @click="goPage(index)"
      >
        <img :src="item.url" />
        <div>{{ item.name }}</div>
      </div>
    </div>
    <!-- 视频号 end -->
    <div class="seize"></div>
    <div class="RQcode-dv" v-if="rqShow" @click="rqShow=false">
      <div class="vedio-RQcode"></div>
    </div>
    <div class="RQcode-dv" v-if="rqShow2" @click="rqShow2=false">
      <div class="vedio-RQcode vedio-RQcode2"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "topBarForPhone",
  data() {
    return {
      phone: false,
      video: false,
      rqShow:false,
      rqShow2:false,
      contactData: [
        {
          title: "业务销售",
          phone: "185-6155-2609",
          address: "fuf@kcxd-tech.com",
          url: require("../../assets/images/phone/u170_div.png"),
        },
        {
          title: "售后服务",
          phone: "153-7673-6865",
          address: "hukx@kcxd-tech.com",
          url: require("../../assets/images/phone/u176_div.png"),
        },
        {
          title: "采购合作",
          phone: "198-6222-8902",
          address: "liuyw@kcxd-tech.com",
          url: require("../../assets/images/phone/u182_div.png"),
        },
        {
          title: "媒体合作",
          phone: "156-2118-8809",
          address: "jiyc@kcxd-tech.com",
          url: require("../../assets/images/phone/u188_div.png"),
        },
        {
          title: "人才招聘",
          phone: "176-6095-8313",
          address: "wangcc@kcxd-tech.com",
          url: require("../../assets/images/phone/u194_div.png"),
        },
      ],
      medioData: [
        { name: "抖音", url: require("../../assets/images/phone/u211.png") },
        { name: "快手", url: require("../../assets/images/phone/u207.png") },
        { name: "视频号", url: require("../../assets/images/phone/u203.png") },
        { name: "公众号", url: require("../../assets/images/phone/u199.png") },
      ],
    };
  },
  props: ["closeFlag"],
  watch: {
    closeFlag() {
      (this.phone = false), (this.video = false);
    },
  },

  methods: {
    phoneSwitch() {
      this.video = false;
      this.phone = !this.phone;
    },
    videoSwitch() {
      this.phone = false;
      this.video = !this.video;
    },
    goPage(index) {
      switch (index) {
        case 0:
          window.open(
            /*  "https://www.douyin.com/user/MS4wLjABAAAAieqQgTOYRssJ9_AgT4O9xPvWr8iE23YqpMsPMVzhLmYEqZbGQ7UVS7wioDCAGije?previous_page=app_code_link" */
            "https://v.douyin.com/RJMr91J"
          );
          break;
        case 1:
          window.open(
            "https://live.kuaishou.com/profile/kcxd2014?fid=2369254049&cc=share_copylink&followRefer=151&shareMethod=TOKEN&kpn=KUAISHOU&subBiz=PROFILE&shareId=16325779715393&shareToken=X-48lMgBiSkch1X2_A&shareResourceType=PROFILE_SELF&shareMode=APP&originShareId=16325779715393&appType=21&shareObjectId=2369254049&shareUrlOpened=0&timestamp=1627607223374"
          );
          break;
        case 2:
        this.rqShow=true


          break
        case 3:
          /* window.open(
            "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzUxMjk4ODEyOA==&scene=124#wechat_redirect"
          ); */
           this.rqShow2=true
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.topBarForPhone {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background: rgba(0, 0, 0, 0.8); */
  background-color: #333333;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  z-index: 99999;
}
.contact {
  img {
    height: 25px;
    width: 25px;
  }
}
.seize {
  height: 50px;
  width: 100%;
}
.twoCode-dv {
  z-index: 9999;
  /*  background: rgba(0, 0, 0, 0.8); */
  background-color: #333333;
  position: absolute;
  right: 0;
  top: 60px;

  img {
    width: 70px;
  }
}
.twoCode-dc-item {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px #fff;
}
.txt1 {
  font-size: 0.8rem;
}
.txt2 {
  font-size: 0.6rem;
  margin-top: 8px;
}
.txt3 {
  font-size: 0.6rem;
}
.video-dv {
  z-index: 9999;
  /*  background: rgba(0, 0, 0, 0.8); */
  background-color: #333333;
  position: absolute;
  right: 0;
  top: 60px;
  color: #fff;
  img {
    width: 50px;
  }
}
.video-item-dv {
  padding: 10px;
  text-align: center;
}
.RQcode-dv {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99999;
 background: rgba($color: #000000, $alpha: 0.6);
}
.vedio-RQcode{
  height: 150px;
  width: 150px;
  background-image: url("../../assets/images/phone/qrvideo.png");
  background-size: 100% 100%;
  background-position-x: center;
  background-position-y: center;
  margin: 150px auto 0 auto;
}
.vedio-RQcode2{
 background-image: url("../../assets/images/phone/qrwechat.jpg");
}

.top-bar-menu-item-sub {
    padding: 5px 10px;
    font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
    font-weight: 900;
    font-size: 12px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid rgba(0, 0, 0, 0);

    &:hover {
        cursor: pointer;
        color: #95F204;
        border-bottom: 2px solid #95F204;
    }
}
</style>
