<template>
  <div class="app-container">
    <div class="app-for-web" v-show="show == 0">
      <div>
        <el-carousel trigger="click" :height="height + 'px'">
          <el-carousel-item>
            <img class="img" src="../assets/images/u1.png" width="100%" @load="imgLoad" />
          </el-carousel-item>
          <el-carousel-item>
            <img class="img" src="../assets/images/u2.png" width="100%" />
          </el-carousel-item>
          <el-carousel-item>
            <img class="img" src="../assets/images/u3.png" width="100%" />
          </el-carousel-item>
          <el-carousel-item>
            <img class="img" src="../assets/images/u4.png" width="100%" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="mt10">
        <view4 />
      </div>
      <div class="mt10">
        <view2 />
      </div>
      <div>
        <view8 />
      </div>
      <div class="mt10">
        <view9 />
      </div>
      <div class="mt10">
        <view10 />
      </div>
      <div class="mt10">
        <view6 />
      </div>
      <div class="mt10">
        <view5 />
      </div>
      <div class="mt10">
        <bottom />
      </div>
    </div>
    <div class="app-for-phone"  v-show="show == 1">
      <homeForPhone></homeForPhone>
    </div>
  </div>
</template>

<script>
import view1 from "@/views/Home/components/view1";
import view2 from "@/views/Home/components/view2";
import view3 from "@/views/Home/components/view3";
import view4 from "@/views/Home/components/view4";
import view5 from "@/views/Home/components/view5";
import view6 from "@/views/Home/components/view6";
import view8 from "@/views/Home/components/view8";
import view9 from "@/views/Home/components/view9";
import view10 from "@/views/Home/components/view10";
import bottom from "@/components/bottom";
import homeForPhone from "@/components/homeForPhone";
export default {
  name: "Home",
  data() {
    return {
      height: 660,
      show: 0,
    };
  },
  components: {
    view1,
    view2,
    view3,
    view4,
    view5,
    view6,
    view8,
    view9,
    view10,
    bottom,
    homeForPhone,
  },
  mounted() {
    if (document.body.clientWidth > 640) {
      this.show = 0
    }else {
      this.show = 1
    }
    window.addEventListener("resize", () => {
      if (document.body.clientWidth > 640) {
        this.show = 0
        this.height = document.getElementsByClassName("img")[0].clientHeight;
      }else {
        this.show = 1
      }
    });
  },
  methods: {
    imgLoad() {
      this.$nextTick(() => {
        if (this.show == 0) {
          this.height = document.getElementsByClassName("img")[0].clientHeight;
        }
      });
    },
    goPath(sub) {
      switch (sub) {
        case 0:
          this.$router.push({ path: '/Project' })
          break
        case 1:
          this.$router.push({ path: '/IOT' })
          break
        case 2:
          this.$router.push({ path: '/Hardware' })
          break
        case 3:
          this.$router.push({ path: '/Case' })
          break
      }
    }
  },
};
</script>
<style>
@media screen and (max-width: 640px) {
  .app-for-web {
    display: none;
  }
  .app-for-phone {
    display: block;
  }
}
@media screen and (min-width: 640px) {
  .app-for-web {
    display: block;
  }
  .app-for-phone {
    display: none;
  }
}
</style>
