<template>
  <div class="overspread flvVideo">
    <video :id="id" :controls="controls" :autoplay="autoplay" :loop="loop" :muted="muted"/>
  </div>
</template>

<script>
import flvjs from 'flv.js'

export default {
  name: 'index',
  props: {
    // 地址
    url: {
      type: String
      // required: true
    },
    // 是否启用音频
    hasAudio: {
      type: Boolean,
      default: false
    },
    // 是否是直播形式
    isLive: {
      type: Boolean,
      default: true
    },
    // 视频流格式
    type: {
      type: String,
      default: 'flv'
    },
    // 是否显示控制台
    controls: {
      type: Boolean,
      default: true
    },
    // 是否自动播放
    autoplay: {
      type: Boolean,
      default: true
    },
    // 是否循环播放
    loop: {
      type: Boolean,
      default: true
    },
    muted: {
      type: Boolean,
      default: true
    },
  },
  watch: {
    url: {
      deep: true,
      handler() {
        this.flvVideo.destroy()
        this.flvVideo = undefined
        this.$nextTick(() => {
          this.initVideo()
        })
      }
    }
  },
  data() {
    return {
      id: new Date().valueOf(),
      flvVideo: undefined,
      state: undefined
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initVideo()
    })
  },
  beforeDestroy() {
    if (this.flvVideo) {
      this.flvVideo.pause()
      this.flvVideo.unload()
      this.flvVideo.detachMediaElement()
      this.flvVideo.destroy()
      this.flvVideo = undefined
    }
  },
  methods: {
    initVideo() {
      let that = this
      if (flvjs.isSupported()) {
        let videoElement = document.getElementById(this.id)
        if (videoElement) {
          this.flvVideo = flvjs.createPlayer({
            url: this.url,
            hasAudio: this.hasAudio,
            isLive: this.isLive,
            type: this.type
          })
          this.flvVideo.attachMediaElement(videoElement)
          this.flvVideo.load()
          // 播放后调用startPlay()，方便用户知道当前视频已播放，可以做其他操作
          this.flvVideo.play().then(res => {
            this.$emit('startPlay')
          })
          // 报错监听
          this.flvVideo.on(flvjs.Events.ERROR, (errType, errDetail) => {
          })
          // 播放监听
          videoElement.addEventListener('play', function (e) {
            // 如果是直播，并且已暂停，则重新加载视频
            if (that.isLive && that.state == 'pause') {
              that.state = 'play'
              that.flvVideo.destroy()
              that.flvVideo = undefined
              that.$nextTick(() => {
                that.initVideo()
              })
            }
          })
          // 暂停监听
          videoElement.addEventListener('pause', function (e) {
            // 如果是直播，则unload
            if (that.isLive) {
              that.flvVideo.unload()
              that.state = 'pause'
            }
          })
        }

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flvVideo {
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
</style>
