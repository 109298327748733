<template>
  <div class="home-view1">
    <div>
      <div class="flex align-items-center justify-content-center">
        <img class="img " v-if="count%3 == 0" src="@/assets/images/u42.png" height="700px"/>
        <img class="img " v-if="count%3 == 1" src="@/assets/images/u43.png" height="700px"/>
        <img class="img " v-if="count%3 == 2" src="@/assets/images/u44.png" height="700px"/>
      </div>
    </div>
    <div class="home-view1-title3" @click="goPage">
      {{ $t('lang.LearnMore') }}
    </div>
  </div>
</template>

<script>
export default {
  name: "view1",
  data() {
    return {
      count: 0,
      interval: undefined
    }
  },
  mounted() {
    this.interval = window.setInterval(() => {
      this.count++
    },2000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    goPage() {
      this.$router.push({ path: '/Hardware' })
    }
  }
}
</script>

<style scoped>
.home-view1 {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-view1-title3 {
  position: absolute;
  top: 120px;
  right: 48vw;
  padding-top: 20px;
  color: #2997ff;
  z-index: 9999;
  cursor: pointer;
}
</style>
