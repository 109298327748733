<template>
  <div class="home-view1 flex align-items-center justify-content-center">
    <div class="home-view4-content">
      <kcTitle style="margin-top: 60px" title="解决方案" en="SOLUTIONS"></kcTitle>
      <div class="flex align-items-center justify-content-between" style="gap: 20px; margin-top: 18px">
        <div class="home-view4-content-item home-view4-content-bg1 pointer">
          <div class="home-view4-content-item-title1" one1>
            <div class="font-size-28 font-bold">CCSDA数据上云一站式解决方案</div>
            <div class="font-size-14 mt5">CCSDA DATA ON THE CLOUD ONE-STOP SOLUTION</div>
          </div>
          <div class="home-view4-content-item-title2 flex flex-column justify-content-between" one2>
            <div>
              <div class="font-size-28 font-bold">CCSDA数据上云一站式解决方案</div>
              <div class="font-size-14 mt5">CCSDA DATA ON THE CLOUD ONE-STOP SOLUTION</div>
            </div>
            <div style="margin-bottom: 120px">
              <img src="../../../assets/images/more-white.png" @click="$router.push({name: 'Core'})" height="46" width="162" class="pointer" style="margin-top: 80px"/>
            </div>
          </div>
        </div>
        <div class="home-view4-content-item home-view4-content-bg2 pointer">
          <div class="home-view4-content-item-title1" two1>
            <div class="font-size-28 font-bold">生物安全</div>
            <div class="font-size-14 mt5">BIOSECURITY</div>
          </div>
          <div class="home-view4-content-item-title2 flex flex-column justify-content-between" two2>
            <div>
              <div class="font-size-28 font-bold">生物安全</div>
              <div class="font-size-14 mt5">BIOSECURITY</div>
            </div>
            <div style="margin-bottom: 120px">
              <img src="../../../assets/images/more-white.png" @click="$router.push({name: 'Core', query: {childType: 1}})"  height="46" width="162" class="pointer" style="margin-top: 80px"/>
            </div>
          </div>
        </div>
        <div class="home-view4-content-item home-view4-content-bg3 pointer">
          <div class="home-view4-content-item-title1" three1>
            <div class="font-size-28 font-bold">老场改造方案</div>
            <div class="font-size-14 mt5">OLD PLANT RENOVATION PROGRAM</div>
          </div>
          <div class="home-view4-content-item-title2 flex flex-column justify-content-between" three2>
            <div>
              <div class="font-size-28 font-bold">老场改造方案</div>
              <div class="font-size-14 mt5">OLD PLANT RENOVATION PROGRAM</div>
            </div>
            <div style="margin-bottom: 120px">
              <img src="../../../assets/images/more-white.png" @click="$router.push({name: 'Core', query: {childType: 2}})"  height="46" width="162" class="pointer" style="margin-top: 80px"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view1",
  data() {
    return {
      count: 0,
      interval: undefined
    }
  },
  methods: {
    goPage() {
      this.$router.push({ path: '/Hardware' })
    }
  }
}
</script>

<style lang="scss" scoped>
.home-view1 {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 120px;
  background: linear-gradient(#F5F5F5 70%, white 70%, white);
  .home-view4-content {
    min-width: 1200px;
    width: 1440px;
  }
  .home-view4-content-item {
    padding: 30px 30px;
    position: relative;
    color: white;
  }
  .home-view4-content-item-title1 {
    position: absolute;
    bottom: 60px;
    left: 30px;
    transition: opacity 0.3s;
  }
  .home-view4-content-item-title2 {
    position: absolute;
    top: 60px;
    left: 30px;
    opacity: 0;
    transition: opacity 0.3s;
    height: 100%;
    color: white;
  }
  .home-view4-content-bg1 {
    background-image: url("../../../assets/images/Home/solutions1.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 724px 411px;
    height: 411px;
    width: calc((100% / 3));
    transition: width 0.3s;
    &:hover {
      width: calc( 50% - 15px);
    }
    &:hover + .home-view4-content-bg2 + .home-view4-content-bg3 {
      width: calc( 25% - 15px);
    }
    &:hover >div[one1]{
      opacity: 0;
    }
    &:hover >div[one2]{
      opacity: 1;
    }
  }
  .home-view4-content-bg2 {
    background-image: url("../../../assets/images/Home/solutions2.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 724px 411px;
    height: 411px;
    width: calc((100% / 3));
    transition: width 0.3s;
    &:hover {
      width: calc( 50% - 15px);
    }
    &:hover + .home-view4-content-bg1 + .home-view4-content-bg3 {
      width: calc( 25% - 15px);
    }
    &:hover >div[two1]{
      opacity: 0;
    }
    &:hover >div[two2]{
      opacity: 1;
    }
  }
  .home-view4-content-bg3 {
    background-image: url("../../../assets/images/Home/solutions3.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 724px 411px;
    height: 411px;
    width: calc((100% / 3));
    transition: width 0.3s;
    &:hover {
      width: calc( 50% - 15px);
    }
    &:hover + .home-view4-content-bg1 + .home-view4-content-bg2 {
      width: calc( 25% - 15px);
    }
    &:hover >div[three1]{
      opacity: 0;
    }
    &:hover >div[three2]{
      opacity: 1;
    }
  }
}
</style>
