export const lang = {
    language: 'lan',
    Home: 'Home', // 科创首页
    CoreBusiness: 'Core Business', // 核心业务
    Solution: 'Solution', // 解决方案
    IoTPlatform: 'IoT Platform', // IoT平台
    IntelligentHardware:'Intelligent hardware', // 智能硬件
    CaseAppreciation:'Case Appreciation', // 案例鉴赏
    AllPartner: 'All Partner', // 全部企业
    Partner: 'Partner', // 合作伙伴
    PoultryEnterprise: 'Poultry enterprise', // 家禽企业
    PigEnterprise: 'Pig enterprise', // 养猪企业
    ConvergedEnterprise: 'Converged enterprise', // 融合企业
    UniversityEnterpriseCooperation: 'University cooperation', // 校企合作
    News: 'News', // 新闻资讯
    AllNews: 'All News', // 全部资讯
    XiaokeHeadline: 'Xiaoke Headline', // 小科头条
    IndustryNews: 'Industry News', // 行业动态
    AboutKCXD: 'About us', // 关于科创
    WhoisKCXD: 'Who is KCXD', // 科创信达是谁
    WhatIsTheCultureOfKCXD: 'The culture of KCXD', // 科创文化是什么
    WhatIsTheRoadToKCXD: 'The road of KCXD', // 科创文之路是什么
    CoreMember: 'Core member', // 核心成员
    ConsultantTeam: '顾问团队', // 顾问团队
    ContactUs: 'Contact us', // 联系我们
    JoinUs: 'Contact us', // 加入我们
    CampusRecruitment: 'Campus recruitment', // 校园招聘
    SocialRecruitment: 'Social recruitment', // 社会招聘
    BusinessCooperation: 'Business cooperation', // 业务合作
    OurAdvantages: 'Our advantages', // 我们的优势
    CurrentStudentsRecentGraduates: 'CurrentStudentsRecentGraduates',   // 在校生&应届毕业生
    IndustryCrossIndustryPersonage: 'Experienced industry personage & ' +
        'excellent cross-industry personage',   // 业内人士&优秀的跨行人士
    FiveAdvantages: 'High sense of responsibility, high sense of mission, high enthusiasm, high initiative, high creativity', // 高责任感、高使命感、高积极性、高主动性、高创造性


    AllCases: 'All cases', // 全部案例
    PigIndustryCase: 'PigIndustryCase', // 猪业案例
    PoultryIndustryCase: 'Poultry industry case', // 禽业案例

    CorporateHQ: 'Corporate HQ', // 公司总部
    ProductionBase: 'Production base', // 生产基地
    address: '25-102, Evergrande Yulan International, 702 Shanhe Road, Chengyang, Qingdao city,China', // 地址
    address2: 'Evergrande Yulan International (North Gate), Chengyang, Qingdao 1000 meters east of the Kechuang Xinda park', // 地址2

    LearnMore: 'Learn more...', // 进一步了解
    InquiryNow: 'Inquiry Now', // 立即询价
    NewsInfo: 'Understand industry trends,pay attention to the life of the IOT', // 了解行业动态，关注物联生活

    IoTInfo: {
        title: 'Xiaoke-AI-Farm O²S big data platform', // 小科爱牧O2S大平台
        msg1: 'Kechuang Xinda is committed to building the Only One System platform, integrating the traditional Xiaoke AI Farm IoT platform with the ERP system, OA office system, ARS accounts receivable system, and EQ electronic quotation system .Solve the information island problem of group enterprises, reduce the cost of group management, and increase the efficiency of group breeding.',
        msg2: 'Through the deep integration of the Internet of Things, big data, cloud computing and other technologies, to create an innovative, coordinated, green, open and sharing Internet platform, to solve the pain points of users, to achieve low risk, fast output, low labor, high quality and high income digitalized breeding, to attract customers and empower brands.'
    },
    advantageInfo: {
        OurAdvantage: 'Our Advantage', // 我们的优势，
        msg1: 'Global', // 一览众山小
        msg2: 'Experience', // 极致的体验
        msg3: 'Safety', // 十分的安全
        msg4: 'Collection', // 精细的采集
        msg5: 'Analysis', // 深度的挖掘
        msg6: 'Usability', // 一触即达
    },
    HardWareInfo: {
        title0: 'Pigs', // 养猪专用环控器
        title1: 'Poultry', // 家禽专用环控器
        title2: 'Guard', // 守护卫士
        title3: 'IOT', // 物联网
        title4: 'auxiliary', // 辅助精灵
        title5: 'Sensors', // 传感器
        title6: 'Other', // 畜禽通用
    },
    WhatIsOurCulture: {
        title: 'What is our company culture?',   //我们的文化是什么
        title1: 'Goal',     //目标
        msg1: 'One team, one decade, one hundred billion',  //一个团队，一个十年，一个千亿
        title2: 'Vision',   // 愿景
        msg2: 'Healthier life, happier family', //更健康的生活，更幸福的家庭
        title3: 'Slogan',   // 口号
        msg3: 'Technological innovation expand the market, Xinda wins the future',  // 科技创新拓市场，信达天下赢未来
        title4: 'Philosophy',   // 理念
        msg4: 'Talents make success',   // 人才造就成功
        title5: 'Driving forces',   // 动能
        msg5: 'Technology Drives Development',  // 科技驱动发展
    },
    WhatIsTheRoadToKechuangXinda: 'What is the road To Kechuang Xinda?',   // 科创之路是什么
    WhatAreOurStrengths: 'What are our strengths?',  // 我们的优势是什么
    TheGrowthOfXiaokeAimu: 'TheGrowthOfXiaokeAimu',   //小科爱牧的成长

}
export const transfer = {
    t: (ctx) => {
        if (dict[ctx.values.t]) {
            return dict[ctx.values.t]
        } else {
            return ctx.values.t
        }
    }
}
const dict = {
}
