<template>
  <div class="platform">
    <div class="platform-content">
      <kcTitle  title="数字化平台" en="DIGITIZATION PLATFORM"></kcTitle>
      <div class="flex justify-content-center justify-content-between" style="margin-top: 60px">
        <div style="width: 50%"><img class="img1" src="../../../assets/images/Home/img/platform.png" width="100%"
                                     @mouseover="scale(10)" @mouseleave="show = false"></div>
        <div>
          <div style="width: 580px; margin-top: 60px">
          <div class="font-size-18 font-bold mb10">
            小科爱牧O2S智慧养殖大数据平台
          </div>
          <div class="font-size-14 mt20" style="line-height: 24px;">
            科创信达致力于打造Only  One System数字大平台，基于客户的企业商业模式设计与未来战略发展规划，将数字技术贯穿“端到端”的企业经营与管理、养殖生产与管理。将智能装备的采集数据沉淀为企业大数据，充分发挥数据底座+云平台的商业价值。通过数字中台赋能企业实际运营管理，横向打通业务流、纵向贯通战略到执行。通过搭建“1+N+N”的平台架构体系实现“上游一盘货、中游一盘帐、下游一盘棋”的数字大平台布局。
             </div>
        </div>
          <img src="../../../assets/images/more.png"  @click="$router.push({name: 'Core', query: {showPage: 1}})"  height="46" width="162" class="pointer" style="margin-top: 40px"/></div>
      </div>
    </div>
    <div>

    </div>
  </div>
</template>

<script>
export default {
  name: "view8"
}
</script>

<style lang="scss" scoped>
.platform{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 60px 0;
  background: #F5F5F5;
  background-image: url("../../../assets/images/Home/text.png");
  background-repeat: no-repeat;
  background-position: 50% 110%;
  .platform-content{
    min-width: 1200px;
    width: 1440px;
  }
}
.img1{
  transition: all 0.3s;
}
.img1:hover{
  transform: scale(1.2);
}

</style>
