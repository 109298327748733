import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
/* Layout */
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/Home',
    children: [
      {
        path: 'Home',
        component: Home,
        name: 'Home',
        meta: { title: '首页', icon: 'dashboard', affix: true, content:{
            keywords:'科创信达-首页',
            description:'科创信达首页',
          } }
      },
      // {
      //   path: 'Core',
      //   component: (resolve) => require(['@/views/Core/index'], resolve),
      //   name: 'Core',
      //   meta: { title: '核心业务', icon: 'dashboard', affix: true }
      // },
      {
        path: 'Core',
        component: (resolve) => require(['@/views/Core/index'], resolve),
        name: 'Core',
        meta: { title: '核心业务', icon: 'dashboard', affix: true }
      },
      {
        path: 'Case',
        component: (resolve) => require(['@/views/Case/index'], resolve),
        name: 'Case',
        meta: { title: '案例赏析', icon: 'dashboard', affix: true }
      },
      {
        path: 'About',
        component: (resolve) => require(['@/views/About/index'], resolve),
        name: 'About',
        meta: { title: '关于科创', icon: 'dashboard', affix: true }
      },
      {
        path: 'Cooperation',
        component: (resolve) => require(['@/views/Cooperation/index'], resolve),
        name: 'Cooperation',
        meta: { title: '合作伙伴', icon: 'dashboard', affix: true }
      },
      {
        path: 'News',
        component: (resolve) => require(['@/views/News/index'], resolve),
        name: 'News',
        meta: { title: '新闻资讯', icon: 'dashboard', affix: true }
      },
      {
        path: 'Project',
        component: (resolve) => require(['@/views/Project/index'], resolve),
        name: 'Project',
        meta: { title: '解决方案', icon: 'dashboard', affix: true }
      },
      {
        path: 'Project-item',
        component: (resolve) => require(['@/views/Project/item'], resolve),
        name: 'Project-item',
        meta: { title: '解决方案', icon: 'dashboard', affix: true }
      },
      {
        path: 'Join',
        component: (resolve) => require(['@/views/Join/index'], resolve),
        name: 'Join',
        meta: { title: '加入我们', icon: 'dashboard', affix: true }
      },
      {
        path: 'IOT',
        component: (resolve) => require(['@/views/IOT/index'], resolve),
        name: 'IOT',
        meta: { title: 'IOT平台', icon: 'dashboard', affix: true }
      },
      {
        path: 'Hardware',
        component: (resolve) => require(['@/views/Hardware/index'], resolve),
        name: 'Hardware',
        meta: { title: '智能硬件', icon: 'dashboard', affix: true }
      },
      {
        path: 'Campus',
        component: (resolve) => require(['@/views/Campus/index'], resolve),
        name: 'Campus',
        meta: { title: '校园招聘', icon: 'dashboard', affix: true }
      },
      {
        path: 'Sociology',
        component: (resolve) => require(['@/views/Sociology/index'], resolve),
        name: 'Sociology',
        meta: { title: '社会招聘', icon: 'dashboard', affix: true }
      },
      {
        path: 'ProjectForPhone',
        component: (resolve) => require(['@/components/projectForPhone'], resolve),
        name: 'ProjectForPhone',
        meta: { title: '解决方案', icon: 'dashboard', affix: true }
      },
      {
        path: 'CaseForPhone',
        component: (resolve) => require(['@/components/caseForPhone'], resolve),
        name: 'CaseForPhone',
        meta: { title: '案例赏析', icon: 'dashboard', affix: true }
      },
      {
        path: 'CooperationForPhone',
        component: (resolve) => require(['@/components/cooperationForPhone'], resolve),
        name: 'CooperationForPhone',
        meta: { title: '合作伙伴', icon: 'dashboard', affix: true }
      },
      {
        path: 'NewsForPhone',
        component: (resolve) => require(['@/components/newsForPhone'], resolve),
        name: 'NewsForPhone',
        meta: { title: '新闻资讯', icon: 'dashboard', affix: true }
      },
      {
        path: 'AboutForPhone',
        component: (resolve) => require(['@/components/aboutForPhone'], resolve),
        name: 'AboutForPhone',
        meta: { title: '关于科创', icon: 'dashboard', affix: true }
      },
      {
        path: 'JoinForPhone',
        component: (resolve) => require(['@/components/joinForPhone'], resolve),
        name: 'JoinForPhone',
        meta: { title: '加入我们', icon: 'dashboard', affix: true }
      },
      {
        path: 'CampusForPhone',
        component: (resolve) => require(['@/components/campusForPhone'], resolve),
        name: 'CampusForPhone',
        meta: { title: '校园招聘', icon: 'dashboard', affix: true }
      },
      {
        path: 'SociologyForPhone',
        component: (resolve) => require(['@/components/sociologyForPhone'], resolve),
        name: 'SociologyForPhone',
        meta: { title: '社会招聘', icon: 'dashboard', affix: true }
      },

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
