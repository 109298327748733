import axios from 'axios'

let host = process.env.VUE_APP_BASE_API //测试服务器-新-本地

// 获取企业新闻
export const getWebsiteNewsInfo = () => {
    return axios.get(`${host}/websiteNewsInfo`)
}

// 获取农场的生产数据表格
export const getWebsiteCase = () => {
    return axios.get(`${host}/websiteCase`)
}
